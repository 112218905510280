import React from "react"
import { graphql } from "gatsby"
import SiteLayout from "../../../components/sitelayout"
import Container from "../../../components/container"
import Header from "../../../components/header"
import SEO from "../../../components/seo"
import {SegmentSubTitle, SegmentText } from "../../../components/segment"
import Screenshot from "../../../components/screenshot"
import FlexColumnWrapper from "../../../components/flexcolumnwrapper"
import MenuItem from "../../../components/menuitem"
import SupportCta from "../../../components/supportcta"
import SubText from "../../../components/subtext"

import { useTheme } from "../../../services/theme"


const AccountDeleteFaq = ({data}) => {
    

    const theme = useTheme()
    return (
        <SiteLayout
            menu={
                <MenuItem to="/help">Help Center</MenuItem>
            }
        >
            <SEO title="FAQ - How do I remove a Hubspot form?"/>
            <Container>
                <Header
                    paddingTop="10vh"
                    paddingBottom="10vh"
                    category="FAQ"
                    maxWidth="960px"
                    title="How do I delete my account?"
                />
                <FlexColumnWrapper 
                    marginBottom="5%"
                    maxWidth="480px"
                    marginLeft="auto"
                    marginRight="auto"
                    breakpoint={theme.breakpoints.mobile}
                    responsiveStyle="padding-left: 20px; padding-right: 20px;"
                >
                    <SegmentSubTitle>Step 1: Start account deletion</SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="10px">
                        &#8594;Click your profile (top right) and click 'Account'.<br/><br/>
                        &#8594;Click 'Delete Account'.<br/><br/>
                    </SegmentText>
                    <Screenshot fluid={data.deleteAccountScreenshot.childImageSharp.fluid} minWidth="340px" maxWidth="480px" marginBottom="40px"/>
                    <SegmentSubTitle id="customattribute">Step 2: Confirm account deletion</SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="20px">                        
                        &#8594;Provide any feedback you have about Vimkit and confirm delete
                        <SubText align="left">Your account will be deleted within 24 hours after you submit your request.
                         Once your account is deleted, all websites and forms in your account will also be removed.</SubText><br/>
                    </SegmentText>
                    <Screenshot fluid={data.confirmDeleteAccountScreenshot.childImageSharp.fluid} minWidth="340px" maxWidth="480px" marginBottom="40px"/>
                </FlexColumnWrapper>
            </Container>
            <SupportCta/>
            
        </SiteLayout>    
    )
}

export default AccountDeleteFaq

export const query = graphql`
    query {
        deleteAccountScreenshot: file(relativePath: { eq: "screenshots/deleteaccount.png" }) {
            childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }  
            }
        }
        confirmDeleteAccountScreenshot: file(relativePath: { eq: "screenshots/confirmdeleteaccount.png" }) {
            childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }  
            }
        }
    }
`